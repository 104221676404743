<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('eshopSubscriptionOrder.id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.variableSymbol" id="filter_vs"
                         :label="$t('eshopSubscriptionOrder.variableSymbol')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.price" id="filter_price"
                         :label="$t('eshopSubscriptionOrder.price')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.email" id="filter_email"
                         :label="$t('eshopSubscriptionOrder.email')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.address_name" id="filter_address_name"
                         :label="$t('eshopSubscriptionOrder.address.name')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.address_surname" id="filter_address_surname"
                         :label="$t('eshopSubscriptionOrder.address.surname')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.address_company" id="filter_address_company"
                         :label="$t('eshopSubscriptionOrder.address.company')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.address_ico" id="filter_address_ico"
                         :label="$t('eshopSubscriptionOrder.address.ico')"></app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30" @click.prevent="filterOrders">
                {{ $t('eshopSubscriptionOrder.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" @click.prevent="exportOrders">
                {{ $t('eshopSubscriptionOrder.filter.export') }}
              </button>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('eshopSubscriptionOrder.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('eshopSubscriptionOrder.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.product"
                :options="products"
                :label="$t('eshopSubscriptionOrder.product')"
                track-by="id"
                id="filter_product"
                optionTitle="name"
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.payment"
                :options="paymentTypeValues"
                :label="$t('eshopSubscriptionOrder.payment')"
                track-by="id"
                id="filter_payment"
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.paymentResult"
                :options="paymentResultTypeValues"
                :label="$t('eshopSubscriptionOrder.paymentResult')"
                track-by="id"
                id="filter_paymentResult"
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30" data-test="order_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopSubscriptionOrder.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import OrderFilterModel from '../../model/eshop/SubscriptionOrderFilter'
import Datepicker from '../form/Datepicker'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import DownloadFileService from '../../services/eshop/DownloadFileService'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'SubscriptionOrderFilter',
  mixins: [PaymentTypeMixin, PaymentResultTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(OrderFilterModel),
      advancedFilter: false
    }
  },
  computed: {
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker
  },
  methods: {
    filterOrders () {
      this.$store.commit('eshopSubscriptionOrder/setPage', 1)
      this.$store.commit('eshopSubscriptionOrder/setFilter', this.filter)
      this.$store.dispatch('eshopSubscriptionOrder/fetch')
    },
    exportOrders () {
      this.$store.dispatch('eshopSubscriptionOrder/export')
        .then((res) => {
          DownloadFileService.download(res, 'objednavky_', '.csv')
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage(this.$t('eshop.notify.export_failed') + ' (' + error + ')')
        })
    },
    resetFilters () {
      this.filter = this._.cloneDeep(OrderFilterModel)
      this.filterOrders()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopSubscriptionOrder/filter']
  }
}
</script>

<style lang="scss">

</style>
